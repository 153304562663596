import React from "react"
import { siteMetadata } from "../../../gatsby-config"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Themes = () => {
  const data = useStaticQuery(graphql`
		{
			allThemesJson {
				nodes {
					bgColor
					title
					icon {
						childImageSharp {
							gatsbyImageData(
                width: 64,
                placeholder: TRACED_SVG
              )
						}
					}
				}
			}
		}
  `)

  return (
    <>
      <h2>Les 4 grands thèmes du {siteMetadata.title}</h2>
      <p className="my-4">
        La mise en œuvre du Label Ville européenne repose sur la grille d’évaluation qui recense des actions concrètes,
        symboliques et pratiques, en faveur de l’Union européenne, répertoriées selon quatre grandes catégories
        d’objectifs :
      </p>
      <ul className="list-unstyled row text-white text-center h5 mt-4 mb-0">
        {data.allThemesJson.nodes.map(({ bgColor, icon, title }, index) => {
          console.log(icon)
          let image = getImage(icon)
          console.log(image)
          return (
            <li key={index} className="col-md-6 col-lg-3 mb-4">
              <div className={`bg-${bgColor} p-3 d-flex flex-row flex-lg-column-reverse align-items-center rounded-lg`}>
                <div className="icon-antoine mr-3 mr-lg-0 my-lg-3">
                  <GatsbyImage
                    image={image}
                    className="img-fluid d-block"
                    alt={title}
                  />
                </div>
                <div className="text-center w-100 text-uppercase">{ title }</div>
              </div>
            </li>
          )
        })}
      </ul>
      <p>Le Label permet ainsi une évaluation simple de ces actions  et donne aux citoyens l’occasion de constater l’engagement européen de leurs élus en un coup d’œil !
      </p>
    </>
  )
}

export default Themes
