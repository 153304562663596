import React from 'react'
import { Link } from 'gatsby'

// Inspiration https://www.mediacurrent.com/blog/how-dynamically-render-button-or-link-drupal-or-gatsby/

const Button = (
  {
    children,
    to,
    href,
    size,
    color,
    className,
    ...props
}) => {
  size = size ? " btn-"+size : ""
  color = color ? color : "light"

  className += ` btn btn-${color}${size} mr-auto`

  if (props['icon']) className += " media"

  const ButtonContent = ({ icon, children }) => {
    if (props['icon']) {
      return (
        <>
          <i className="feather-icons-nav align-self-center" data-feather={icon}/>
          <span className="media-body">{ children }</span>
        </>
      )
    }
    else {
      return children
    }
  }

  // If the `to` prop exists, return a link.
  if (to) {
    return (
      <Link
        className={className}
        to={to}
      >
        <ButtonContent>{children}</ButtonContent>
      </Link>
    );
  }

  // if the `to` prop does not exist but `href` does, return a <a> element.
  if (href) {
    return (
      <a
        className={className}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        <ButtonContent>{children}</ButtonContent>
      </a>
    );
  }

  // If the `to` or `href` props do not exist, return a <button> element.
  return (
    <button
      aria-expanded={props['aria-expanded'] || null}
      className={className}
      {...props}
    >
      <ButtonContent>{children}</ButtonContent>
    </button>
  );
}

export default Button
