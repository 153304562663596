import React from "react"
import HomeBackground from "./Home/HomeBackground";
import Header from "./Header";
import Container from "./Container"
import CallToAction from "./CallToAction"
import Footer from "./Footer";
import Section from "./Section"

export default function Layout({ isHomePage, children }) {
  return (
      <>
        {isHomePage && <HomeBackground/>}
        <Header isHomePage={isHomePage} />
        <Container hideOverflow>
          <main className="px-3 px-md-5">
            { children }
            <Section backgroundColor="yellow">
              <CallToAction/>
            </Section>
          </main>
        </Container>
        <Footer/>
      </>
  )
}
