import React from "react";
import Container from "./Container"
import Button from "./ui/buttons"

const Column = ({ children, last }) => <div className={`col-12 col-md-6 mt-3${last ? " mb-3" : ""}`}>{ children }</div>

const CallToAction = ({ color }) => {
  color = color ? color : "light"

  return (
    <div className={`overflow-hidden`}>
      <Container>
        <div className="row">
          <Column>
            <Button
              size="lg"
              color={color}
              to="/assets/uploads/Label-Ville-UE-Charte.pdf"
              icon="download"
            >
              Consulter la charte
            </Button>
          </Column>
          <Column last>
            <Button
              size="lg"
              color={color}
              to="mailto:ville-europeenne@jeunes-europeens.org"
              icon="send"
            >
              Nous contacter
            </Button>
          </Column>
        </div>
      </Container>
    </div>
  )
}
export default CallToAction
