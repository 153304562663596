/**
 * Configure your Gatsby site with this file.
 *
 * See: https://www.gatsbyjs.com/docs/gatsby-config/
 */

module.exports = {
  siteMetadata: {
    title: 'Label Ville Européenne',
    description: "À l'occasion des élections municipales 2020 et au-delà, un label 100% citoyen et transpartisan qui récompense les communes pour leur engagement européen.",
    siteURL: "https://www.ville-europeenne.org/",
    social: {
      twitter: {
        url: `https://twitter.com/VilleEuropeenne`
      },
      facebook: {
        url: `https://www.facebook.com/JeunesEuropeens/`
      }
    },
    menuLinks: [
      {
        name: "Le réseau",
        url: "/le-reseau"
      }
    ]
  },
  plugins: [
    `gatsby-plugin-sass`,
    `gatsby-plugin-image`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/static/images`,
      },
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    `gatsby-transformer-json`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `data`,
        path: `${__dirname}/src/data/`,
        ignore: [`**/.*`], // ignore files starting with a dot
      },
    },
  ],
}
