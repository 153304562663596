import React from "react";

const Container = ({ children, padding, hideOverflow }) => {
  padding = padding ? " "+padding : ""
  hideOverflow = hideOverflow ? " overflow-hidden" : ""

  return (
    <div className={`container-fluid px-lg-0${hideOverflow}`}>
      <div className={`container-lg px-0${padding}`}>
        { children }
      </div>
    </div>
  )
}
export default Container
