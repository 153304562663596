import React from "react"
import { Link } from "gatsby"
import { siteMetadata } from "../../gatsby-config"
import { StaticImage } from "gatsby-plugin-image";
import Container from "./Container"

const Header = ({ location }) => {
  return (
    <header className="bg-light sticky-top" id="top">
      <Container>
        <nav className="navbar navbar-expand-md navbar-light bg-light">
          <Link className="navbar-brand" to="/#top">
            <StaticImage
              src="./../../static/images/label-ville-europeenne.png"
              height={50}
              loading="eager"
              placeholder="none"
              className="d-inline-block align-top"
              alt={siteMetadata.title}
            />
          </Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse"
                  data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                  aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"/>
          </button>
          <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
            <ul className="navbar-nav">
              {
                siteMetadata.menuLinks.map(link => (
                  <li key={link.name} className="nav-item{% if page.url == item.link %} active{% endif %}">
                    <Link className="nav-link" to={link.url}>{link.name}</Link>
                  </li>
                ))
              }
              <li className="nav-item">
                <a className="nav-link" href={siteMetadata.social.twitter.url}>
                  <i className="feather-icons-nav align-self-center" data-feather="twitter" stroke="none"
                     fill="#1DA1F2">Twitter</i>
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </Container>
    </header>
  )
}

export default Header
