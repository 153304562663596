import React from "react"
import { siteMetadata } from "../../../gatsby-config";
import { StaticImage } from "gatsby-plugin-image";

const HomeBackground = () => {
  return (
    <div className="overflow-hidden home-background" id="header_homepage">
      <div className="container-fluid px-lg-0 position-fixed">
        <div className="container-lg px-0">
          <div className="d-flex flex-column flex-md- align-items-center pt-3 pt-md-5 pb-3">
            <h1 className="text-center">
              <StaticImage
                src="./../../../static/images/label-ville-europeenne-blanc.png"
                width={500}
                loading="eager"
                placeholder="none"
                className="img-fluid"
                alt={siteMetadata.title}
              />
            </h1>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeBackground
