import React from "react"
import Layout from "../components/Layout"
import Section from "../components/Section"
import { Lead } from "../components/ui/utils"
import { siteMetadata } from "../../gatsby-config"
import Button from "../components/ui/buttons"

export default function Réseau() {
  return <Layout>
    <Section padding="pt-5 pb-4">
      <h1>Le réseau</h1>
      <Lead>Déjà 38 communes ont signé la charte d’engagement et font partie du réseau Label Ville européenne.
        Comme Arras, Rouen, Lille, Strasbourg, Paris, Nantes, ainsi que Tournefeuille, Marly, Achicourt : la diversité
        des communes du réseau fait sa force !
      </Lead>
    </Section>

    <Section>
      <h1>Pourquoi obtenir le {siteMetadata.title} ?</h1>
      <p>Obtenir le Label Ville européenne permet à votre commune de :</p>
      <ul>
        <li>faire partie d’un réseau de communes motivées par les questions européennes déjà fort de son succès : 39
          communes à ce jour font partie du réseau des villes labellisées !
        </li>
        <li>bénéficier du conseil des membres bénévoles experts des associations porteuses du Label et pouvoir compter
          sur leur réseau pour mettre en œuvre des actions pédagogiques sur l’Union européenne au sein de votre commune
          ;
        </li>
        <li>profiter d’une meilleure visibilité par le relai des actions innovantes mises en œuvre par la commune sur
          les réseaux sociaux du Label Ville européenne ;
        </li>
        <li>avoir l’occasion de participer à un séminaire national annuel d’échanges et de partage des bonnes pratiques
          sur les questions européennes réunissant les élus des communes signataires !
        </li>
      </ul>
    </Section>

    <Section>
      <h1>Qui peut signer la charte d’engagement ?</h1>
      <p>Toute commune française remplissant les critères de niveau 1 du Label peut signer la Charte d’engagement, et
        doit l’envoyer à l’adresse ville-europeenne@jeunes-europeens.org. </p>
      <p>Une commune est considérée comme faisant partie du réseau des villes labellisées une fois que la charte
        d’engagement est accusée bonne réception par mail par un des membres d’une des trois associations partenaires.</p>
    </Section>

    <Section backgroundColor="blue" padding="py-5">
      <p>Si vous voulez vous engager encore plus dans la mise en œuvre du Label et passer à une étape supérieure en
        termes d’engagement, vous pouvez candidater à l’obtention de la Plaque Ville européenne …</p>
      <Button
        to="/la-plaque"
      >
        Plus d'informations
      </Button>
    </Section>

  </Layout>
}
