import React from "react"
import { siteMetadata } from "../../gatsby-config"
import Layout from "../components/Layout"
import Section from "../components/Section"
import CallToAction from "../components/CallToAction"
// import flagsGIF from "./../../static/images/drapeaux.gif"
// import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Themes from "../components/Home/HomeThemes"
import { Highlight, Lead } from "../components/ui/utils"

const Intro = () => {
  return (
    <>
      <Lead>
        Le {siteMetadata.title}, <em>100% citoyen et transpartisan</em>, identifie les communes membres du réseau comme
        des <Highlight>acteurs clés dans la promotion de la démocratie européenne</Highlight>.
      </Lead>
      <Lead>
        Dans l’objectif de faire vivre l’Union européenne dans nos villes et nos villages, il incite les responsables
        politiques locaux à se saisir des thématiques européennes et à <Highlight>mettre en place des actions
        concrètes</Highlight> pour la promotion de la citoyenneté européenne au sein de leur commune.
      </Lead>
    </>
  )
}

const Story = () => {
  return (
    <>
      <p>
        Le {siteMetadata.title} a vu le jour grâce à la collaboration de trois associations partenaires : les Jeunes
        Européens – France, le Mouvement Européen – France et l’Union des Fédéralistes Européens – France.
      </p>
      <p>
        En amont des élections municipales de 2020, les bénévoles des 3 associations porteuses du Label se sont
        mobilisés auprès des candidats afin de les sensibiliser à l’importance d’intégrer les questions européennes au
        niveau local. Fort de son succès, le démarrage du {siteMetadata.title} s’est vu conforté par la signature de
        <Highlight>122 candidats provenant de tout bord politique, dans plus de 87 communes</Highlight>.
      </p>
      <p>
        Aujourd’hui, le {siteMetadata.title} continue de se développer et de se consolider grâce à la bonne entente et
        la coopération avec les élus des communes signataires.
      </p>
    </>
  )
}

/*const Levels = () => {
  return (
    <>
      <p>
        Il comprend cinq niveaux qui permettent aux citoyens de voir en un coup d'oeil les réalisations
        européennes dans leur commune et reflètent l'engagement européen d'une municipalité.
      </p>
      <div className="row mb-n3">
        <div className="col-8 col-md-10 col-lg-8 col-xl-6">
          <img src={flagsGIF} alt="" className="img-fluid" />
        </div>
      </div>
    </>
  )
}*/

export default function Home() {
  return (
    <Layout isHomePage>
      <Section padding="pt-5 pb-4">
        <Intro />
      </Section>
      <Section padding="">
        <Story />
      </Section>
      <Section padding="">
        <Themes />
      </Section>
      <Section padding="pt-0 pb-4">
        <CallToAction color="yellow" />
      </Section>
      <Section backgroundColor="blue">
        <h1>test</h1>
      </Section>
    </Layout>
  )
}
