import React from "react"
import Layout from "../components/Layout"
import Section from "../components/Section"
import { Lead } from "../components/ui/utils"

export default function Plaque() {
  return <Layout>
    <Section padding="pt-5 pb-4">
      <h1>Qu’est ce que la Plaque Ville européenne ?</h1>
      <p>
        Directement visible à l’entrée des communes, la Plaque Ville européenne est une récompense prestigieuse qui
        symbolise l’engagement fort et novateur de la commune dans la promotion de la démocratie européenne.
      </p>
      <p>
        Les communes ayant la Plaque Ville européenne s’engagent ainsi à être des villes modèles en termes de
        promotion de la démocratie européenne sur les territoires pour les communes du réseau des villes labellisées.
      </p>
      <p>
        Les engagements qui les lient aux associations porteuses du Label Ville européenne sont plus forts.
      </p>
    </Section>
    <Section>
      <h1>Pourquoi obtenir la Plaque ville européenne ? </h1>
      <p>Obtenir la Plaque Ville européenne, c’est:</p>
      <ul>
        <li>faire partie d’un réseau resserré de partenaires privilégiés à l’échelle nationale qui se démarquent par leurs initiatives innovantes dans la promotion de la démocratie européenne sur leur territoire ;</li>
        <li>s’engager dans une coopération renforcée avec les 3 associations porteuses du label ;</li>
        <li>voir ses actions en faveur de la démocratie européenne mises en valeur lors de la journée de dissémination annuelle nationale prévue à l'automne 2021.</li>
      </ul>
    </Section>
    <Section>
      <h1>Les engagements des communes ayant la Plaque</h1>
      <p>Les communes qui font la demande de la Plaque Ville européenne s’engagent à :</p>
      <ul>
        <li>établir et mettre en oeuvre un programme d’actions équilibré sur les 4 thématiques du Label Ville européenne ;</li>
        <li>rendre un rapport d’activité annuel au Comité de pilotage du Label sur la manière dont elles font vivre le Label Ville européenne sur leur territoire ;</li>
        <li>intégrer le Label Ville européenne dans leur communication municipale ; </li>
        <li>participer à la journée annuelle d’échanges et de partage des bonnes pratiques prévue cette année en octobre 2021</li>
      </ul>
    </Section>
    <Section>
      <h1>Qui peut obtenir la plaque ville européenne ?</h1>
      <p>Toute commune faisant partie du réseau des Villes labellisées peut envoyer sa demande de candidature pour l’obtention de la Plaque ville européenne à l’adresse ville-europeenne@jeunes-europeens.org.</p>
    </Section>
    <Section>
      <h1>Les villes ayant la plaque Ville européenne</h1>
      <p>Arras est la première ville à nous avoir rejoint : l’inauguration de la première plaque a eu lieu le samedi 17 octobre 2020 ! </p>
    </Section>
  </Layout>
}
