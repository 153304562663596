import React from "react";
import { siteMetadata } from './../../gatsby-config'
import { StaticImage } from "gatsby-plugin-image";
import Container from "./Container"

const Footer = () => {
  return (
    <footer className="bg-dark text-white overflow-hidden">
      <Container padding="py-5">
        <div className="d-flex flex-column align-items-center">
          <h1 className="h5">Le projet <span className="font-italic">{siteMetadata.title}</span> est porté
            par trois associations transpartisanes :</h1>
          <ul className="d-flex flex-column flex-md-row align-items-center list-unstyled">
            <li className="my-2 mx-4">
              <a href="https://www.jeunes-europeens.org/">
                <StaticImage
                  src="./../../static/images/logo-je.png"
                  width={200}
                  placeholder="tracedSVG"
                  alt="Les Jeunes Européens - France,"
                />
              </a>
            </li>
            <li className="my-2 mx-4">
              <a href="https://mouvement-europeen.eu/">
                <StaticImage
                  src="./../../static/images/logo-mef.png"
                  width={200}
                  placeholder="tracedSVG"
                  alt="le Mouvement Européen - France,"
                />
              </a>
            </li>
            <li className="my-2 mx-4">
              <a href="https://www.uef.fr/">
                <StaticImage
                  src="./../../static/images/logo-uef.png"
                  width={200}
                  placeholder="tracedSVG"
                  alt="l'Union des Fédéralistes européens"
                />
              </a>
            </li>
          </ul>
          <p className="text-center font-weight-lighter">
            <a href="https://www.jeunes-europeens.org/" className="font-italic text-white">Les Jeunes Européens – France</a>, le <a href="https://mouvement-europeen.eu/" className="font-italic text-white">Mouvement Européen – France</a> et l’<a href="https://www.uef.fr/" className="font-italic text-white">Union des Fédéralistes Européens – France</a> sont des associations citoyennes qui se définissent comme un lieu de débats, d’information et de propositions tout en respectant le pluralisme politique qui leur interdit toute action politique partisane. Elles revendiquent leur caractère transpartisan qui s’affirme par le dialogue avec l’ensemble des partis politiques sans toutefois n’en soutenir aucun.<br />
            <strong className="font-weight-bold">Les trois associations et leurs sections locales s’interdisent ainsi d’appeler au vote pour un parti politique ou un candidat, quel qu’il soit.</strong>
          </p>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
